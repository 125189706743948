@keyframes marquee-horizontal {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.marquee-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
}

.app-marquee-content {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  animation: marquee-horizontal 60s linear infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform;
}

.app-card {
  flex: 0 0 auto;
  min-width: 100px;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

@media (min-width: 640px) {
  .app-card {
    min-width: 120px;
  }
  
  .app-marquee-content {
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .app-card {
    min-width: 140px;
  }
  
  .app-marquee-content {
    gap: 2rem;
  }
} 